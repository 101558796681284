import React, { useEffect } from "react";

const CursorTrails = () => {
  useEffect(() => {
    let mouseX = 0;
    let mouseY = 0;
    let prevMouseX = 0;
    let prevMouseY = 0;
    let isMouseMoving = false;
    const particleInterval = 50; // Time in milliseconds between particle generations

    // Ensure the body has the correct positioning
    document.body.style.position = "relative";

    const createRay = (x1, y1, x2, y2) => {
      const length = Math.hypot(x2 - x1, y2 - y1); // Length of the line
      const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI); // Angle of the line
      const opacity = Math.random() * 0.5 + 0.5; // Random opacity between 0.5 and 1
      const lifespan = 1000; // Lifespan in milliseconds

      const ray = document.createElement("div");
      ray.style.position = "absolute";
      ray.style.left = `${x1}px`;
      ray.style.top = `${y1}px`;
      ray.style.width = `${length}px`;
      ray.style.height = `2px`; // Fixed height for the line
      ray.style.background = `linear-gradient(90deg, rgba(255, 255, 255, ${opacity}) 0%, rgba(255, 215, 0, ${opacity}) 50%, rgba(255, 255, 255, ${opacity}) 100%)`;
      ray.style.boxShadow = `0 0 10px rgba(255, 215, 0, ${opacity}), 0 0 20px rgba(255, 215, 0, ${opacity})`;
      ray.style.transform = `rotate(${angle}deg)`;
      ray.style.transformOrigin = "0 0"; // Rotate around the starting point
      ray.style.opacity = opacity;
      ray.style.pointerEvents = "none"; // Ensure rays do not block interactions
      ray.style.transition = `opacity ${lifespan / 1000}s linear`;

      document.body.appendChild(ray);

      setTimeout(() => {
        ray.style.opacity = 0;
        setTimeout(() => {
          if (ray.parentNode === document.body) {
            document.body.removeChild(ray);
          }
        }, lifespan);
      }, 0);
    };

    const animate = () => {
      if (isMouseMoving) {
        createRay(prevMouseX, prevMouseY, mouseX, mouseY);
        isMouseMoving = false; // Reset the flag after creating the ray
      }
      requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      prevMouseX = mouseX;
      prevMouseY = mouseY;
      mouseX = event.clientX + window.scrollX;
      mouseY = event.clientY + window.scrollY;
      isMouseMoving = true;
    };

    document.addEventListener("mousemove", handleMouseMove);
    requestAnimationFrame(animate);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return null;
};

export default CursorTrails;