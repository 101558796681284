import React from "react";
import { useNavigate } from "react-router-dom";

function Register() {
   const navigate = useNavigate();

   const handleRegisterNow = () => {
      navigate("/register"); // Navigate to Howzat registration for now removed '/howzat' to stop registeration page re-direction and commented out regsiter button
   };

   return (
      <div className="w-full min-h-screen bg-[#202c62] flex flex-col items-center text-white">
         <div className="w-full top-0 left-0 m-0 p-0">
            <picture>
               <source media="(max-width: 767px)" srcSet="/banner-ph.svg" />
               <img src="/banner.svg" alt="Banner" className="w-full mt-10" />
            </picture>
         </div>
         <div className="flex items-center justify-center">
            <div className="detailsBox flex flex-col md:flex-row gap-5 mb-10">
               <div className="mb- 50 mr-5 ml-5 p-6 font-mono border-4 border-yellow-500 rounded-xl">
                  <h2 className="text-3xl font-bold text-center mb-4">
                     Event Details
                  </h2>
                  <ul className="list-disc text-lg list-inside font-mono">
                     <li>Date: 1st SEPTEMBER, 2024</li>
                     <li>Day: Sunday</li>
                     <li>Reporting Time: 8:30 am</li>
                     <li>
                        Location: Tiger Play, Lokhandwala (
                        <a
                           href="https://maps.app.goo.gl/yGc2Uc8D4qghMJua6"
                           target="_blank"
                           rel="noopener noreferrer"
                           className="text-blue-300 underline"
                        >
                           Google Maps Link
                        </a>
                        )
                     </li>
                     <li>Entry Fees: 2240/- per TEAM (320/- per player)</li>
                     <li>Prize Pool: WORTH 35,000/-</li>
                  </ul>
               </div>

               <div className="p-6 mr-5 ml-5  border-4 border-yellow-500 rounded-xl">
                  <h2 className="text-3xl font-bold text-center mb-4">
                     General Rules
                  </h2>
                  <ul className="list-disc list-inside text-lg font-mono">
                     <li>
                        All team members should be from TSEC (FE, SE, TE, BE).
                     </li>
                     <li>
                        Each team should have 7 players (Minimum 1 female
                        player).
                     </li>
                     <li>Each match will be of 5 overs per innings.</li>
                     <li>
                        One bowler can only bowl one over in a normal match.
                     </li>
                     <li>
                        In the final, a maximum of 2 overs per bowler is
                        allowed.
                     </li>
                     <li>Female player must compulsorily bowl 1 over.</li>
                     <li>
                        Disrespecting the umpire's decisions will result in
                        disqualification.
                     </li>
                     <li>
                        IETE reserves rights with regards to the final decision.
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <button 
            onClick={handleRegisterNow}
            className="text-black bg-yellow-500 border-4 border-white px-4 py-3 rounded-2xl text-lg font-bold mt-4 mb-5"
         >
            Register Now
         </button>
      </div>
   );
}

export default Register;
