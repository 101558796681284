// Home.jsx

import React from "react";
import Sponsors from "../../components/Sponsors/Sponsors";
import HomeEvents from "../../components/HomeEvents/HomeEvents";
import Clock from "../../components/Clock/Clock";
import MainPage from "./MainPage";
import ParticlesBackground from "./ParticlesBackground";

const Home = () => {
   return (
      <div className="bg-primary text-blue-400 text-xl font-bold uppercase flex flex-col items-center justify-center w-full overflow-hidden">
         <ParticlesBackground />
         <div className="relative z-10">
            <MainPage />
         </div>
         <div className="relative z-10">
            <Clock />
         </div>
         <div className="relative z-30">
            <HomeEvents />
         </div>
         <div className="w-screen relative z-30">
            <Sponsors />
         </div>
      </div>
   );
};

export default Home;
