import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './Elan.css';
import mainImage from './elanHall.png'; // Path to your main image
import mainImage2 from './elan-hall.png'; // Path to your alternative main image

const Elan = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const mainImageRef = useRef(null);
  const [textVisible, setTextVisible] = useState(false); // State to control text visibility

  useEffect(() => {
    // Handle screen size changes
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Fade in the main image
    const handleFadeIn = () => {
      if (mainImageRef.current) {
        mainImageRef.current.classList.add('fade-in');
      }
    };

    setTimeout(handleFadeIn, 1500);

    // Show the text after the image fades in
    setTimeout(() => {
      setTextVisible(true);
    }, 2800); // Adjust the delay as needed (this is 1 second after the image fades in)
  }, []);

  return (
    <div className="elan-container">
      {isSmallScreen ? (
        <img
          src={mainImage2}
          alt="Main Small Screen"
          ref={mainImageRef}
          className="main-image2"
        />
      ) : (
        <img
          src={mainImage}
          alt="Main Large Screen"
          ref={mainImageRef}
          className="main-image"
        />
      )}
      {textVisible && ( // Conditionally render the text when it's visible
        <div className="elan-text fade-in-text">
          <div className="committee-name">IETE TSEC</div>
          <div className="present">PRESENTS</div>
          <div className="event-name">ELAN</div>
        </div>
      )}
    </div>
  );
};

export default Elan;
