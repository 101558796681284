import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import './GoldenSnitch.css';

// Register MotionPathPlugin
gsap.registerPlugin(MotionPathPlugin);

const GoldenSnitch = () => {
  const [isStopped, setIsStopped] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [hideSnitch, setHideSnitch] = useState(false);

  const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate("/elan/tube");
    }

  useEffect(() => {
    const hideSnitchDuration = 4.4; // Time after which to hide the snitch and show button

    // Path definition
    const path = [
      { x: '0%', y: '-30%' },   // Top center
      { x: '20%', y: '-30%' },   // Right top
      { x: '25%', y: '-15%' },
      { x: '30%', y: '0%' },   // Bottom right
      { x: '20%', y: '30%' },   // Left bottom
      { x: '0%', y: '30%' },   // Bottom center
      { x: '-20%', y: '30%' },   // Left bottom
      { x: '-30%', y: '0%' },   // Bottom left
      { x: '-10%', y: '-20%' },   // Right top
      { x: '0%', y: '20%' }    // Back to top center
    ];
    

    // Create timelines for the animation
    const flyingTimeline = gsap.timeline({ repeat: -1 });
    const movingTimeline = gsap.timeline({ repeat: -1 });

    // Flapping wings animation
    flyingTimeline.to("#leftwing", { rotate: -35, duration: 0.06, yoyo: true, repeat: -1 });
    flyingTimeline.to("#rightwing", { rotate: 35, duration: 0.06, yoyo: true, repeat: -1 });
    flyingTimeline.to("#snitch", { y: -10, duration: 1, ease: "linear", yoyo: true, repeat: -1 });

    // Moving animation of the .test div along the path
    movingTimeline.to(".test", {
      duration: 5, // Total duration to complete the path
      motionPath: {
        path: path,
        align: ".test",
        autoRotate: true
      },
      ease: "power1.inOut",
      repeat: -1
    });

    // Hide snitch and show button after hideSnitchDuration
    const onAnimationEnd = () => {
      setShowButton(true);
      gsap.to("#registerButton", { opacity: 1, visibility: 'visible', duration: 1 });
      gsap.to(".test", { opacity: 0, duration: 1, onComplete: () => setHideSnitch(true) });
    };

    gsap.delayedCall(hideSnitchDuration, onAnimationEnd); // Trigger after hideSnitchDuration seconds

    const handleStop = () => {
      if (!isStopped) {
        gsap.to("#leftwing", { rotate: -50, duration: 0.5 });
        gsap.to("#rightwing", { rotate: 50, duration: 0.5 });
        flyingTimeline.pause();
        movingTimeline.pause();
        setIsStopped(true);
      } else {
        gsap.to("#leftwing", { rotate: 0, duration: 0.5 });
        gsap.to("#rightwing", { rotate: 0, duration: 0.5 });
        flyingTimeline.play();
        movingTimeline.play();
        setIsStopped(false);
      }
    };

    // Attach event listeners
    document.addEventListener("click", handleStop);
    document.addEventListener("keydown", (e) => {
      if (e.key === " ") handleStop();
    });

    return () => {
      document.removeEventListener("click", handleStop);
      document.removeEventListener("keydown", (e) => {
        if (e.key === " ") handleStop();
      });
    };
  }, [isStopped]);

  return (
    <>
      {!hideSnitch && (
        <div className='test'>
          <div id="shadow"></div>
          <div id="bg">
            <div></div>
          </div>
          <div id="snitch">
            <div id="body">
              <div id="e-line"></div>
              <div id="e-circle"></div>
              <div id="b-circle">
                <div style={{ "--i": 0 }}></div>
                <div style={{ "--i": 1 }}></div>
                <div style={{ "--i": 2 }}></div>
                <div style={{ "--i": 3 }}></div>
                <div style={{ "--i": 4 }}></div>
              </div>
            </div>
            <div id="eye"></div>
            <div id="wing">
              <div style={{ "--i": 0 }}></div>
              <div style={{ "--i": 1 }}></div>
              <div style={{ "--i": 2 }}></div>
              <div style={{ "--i": 3 }}></div>
              <div style={{ "--i": 4 }}></div>
              <div style={{ "--i": 5 }}></div>
              <div style={{ "--i": 6 }}></div>
              <div style={{ "--i": 7 }}></div>
              <div style={{ "--i": 8 }}></div>
              <div style={{ "--i": 9 }}></div>
              <div style={{ "--i": 10 }}></div>
              <div style={{ "--i": 11 }}></div>
              <div style={{ "--i": 12 }}></div>
              <div style={{ "--i": 13 }}></div>
              <div style={{ "--i": 14 }}></div>
              <div style={{ "--i": 15 }}></div>
              <div style={{ "--i": 16 }}></div>
            </div>
            <div id="wing2">
              <div style={{ "--i": 0 }}></div>
              <div style={{ "--i": 1 }}></div>
              <div style={{ "--i": 2 }}></div>
              <div style={{ "--i": 3 }}></div>
              <div style={{ "--i": 4 }}></div>
              <div style={{ "--i": 5 }}></div>
              <div style={{ "--i": 6 }}></div>
              <div style={{ "--i": 7 }}></div>
              <div style={{ "--i": 8 }}></div>
              <div style={{ "--i": 9 }}></div>
              <div style={{ "--i": 10 }}></div>
              <div style={{ "--i": 11 }}></div>
              <div style={{ "--i": 12 }}></div>
              <div style={{ "--i": 13 }}></div>
              <div style={{ "--i": 14 }}></div>
              <div style={{ "--i": 15 }}></div>
              <div style={{ "--i": 16 }}></div>
            </div>
          </div>
        </div>
      )}
      {showButton && (
        <button id="registerButton" className="show" onClick={handleRegisterClick}>Register</button>
      )}
    </>
  );
};

export default GoldenSnitch;
