import React from "react";
import "./Splash.css";
import { AnimatePresence, motion } from "framer-motion";

function Splash() {
   return (
      <AnimatePresence>
         <motion.div
            className="splashbg bg-primary"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ delay: 4, duration: 0.5 }}
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 642.67 872.99"
               width="642.6699829101562"
               height="872.989990234375"
               className="logoSplash"
            >
               <defs>
                  <style>
                     {`.cls-1,.cls-2,.cls-3,.cls-4{fill:none;stroke:#fff;}.cls-1,.cls-2,.cls-3{stroke-miterlimit:10;}.cls-1,.cls-2{stroke-width:20px;}.cls-2,.cls-3,.cls-4{stroke-linecap:round;}.cls-3{stroke-width:12px;}.cls-4{stroke-linejoin:round;stroke-width:11px;}`}
                  </style>
               </defs>
               <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                     <path
                        className="cls-1 svg-elem-1"
                        d="M536.22,321.62c1.53,57.38-21.92,96.94-51.91,139.74-28.12,40.12-51,70.22-51,119.22l1,22.51a20.46,20.46,0,0,1-20.47,20.47H225.77a20.47,20.47,0,0,1-20.47-20.47l2-23c0-49.11-24-79.29-52.49-119.29-31.51-44.24-51.51-78.44-51.51-133.24,0-119.45,93.87-216.52,212.86-218.47C436.35,107.13,533,201.45,536.22,321.62Z"
                     />
                     <line
                        className="cls-2 svg-elem-2"
                        x1="225.8"
                        y1="658.06"
                        x2="225.8"
                        y2="742.1"
                     />
                     <line
                        className="cls-2 svg-elem-3"
                        x1="234.78"
                        y1="737.06"
                        x2="413.8"
                        y2="737.06"
                     />
                     <line
                        className="cls-2 svg-elem-4"
                        x1="234.78"
                        y1="700.08"
                        x2="413.8"
                        y2="700.08"
                     />
                     <line
                        className="cls-2 svg-elem-5"
                        x1="234.78"
                        y1="663.09"
                        x2="413.8"
                        y2="663.09"
                     />
                     <line
                        className="cls-2 svg-elem-6"
                        x1="222.3"
                        y1="335.06"
                        x2="417.3"
                        y2="335.06"
                     />
                     <line
                        className="cls-2 svg-elem-7"
                        x1="244.49"
                        y1="366.31"
                        x2="395.11"
                        y2="366.31"
                     />
                     <line
                        className="cls-2 svg-elem-8"
                        x1="319.8"
                        y1="400.06"
                        x2="319.8"
                        y2="558.85"
                     />
                     <line
                        className="cls-2 svg-elem-9"
                        x1="249.93"
                        y1="587.06"
                        x2="389.68"
                        y2="587.06"
                     />
                     <line
                        className="cls-3 svg-elem-10"
                        x1="319.8"
                        y1="6"
                        x2="319.8"
                        y2="73.06"
                     />
                     <line
                        className="cls-3 svg-elem-11"
                        x1="516.37"
                        y1="62.08"
                        x2="476.05"
                        y2="115.66"
                     />
                     <line
                        className="cls-3 svg-elem-12"
                        x1="636.67"
                        y1="260.82"
                        x2="572.51"
                        y2="280.34"
                     />
                     <line
                        className="cls-3 svg-elem-13"
                        x1="119.98"
                        y1="69.18"
                        x2="162.23"
                        y2="121.25"
                     />
                     <line
                        className="cls-3 svg-elem-14"
                        x1="6"
                        y1="257.97"
                        x2="69.22"
                        y2="280.35"
                     />
                     <line
                        className="cls-4 svg-elem-15"
                        x1="216.44"
                        y1="813.17"
                        x2="216.44"
                        y2="867.49"
                     />
                     <polyline
                        className="cls-4 svg-elem-16"
                        points="288.38 867.49 246.28 867.49 246.28 840.33 246.28 813.17 288.38 813.17"
                     />
                     <line
                        className="cls-4 svg-elem-17"
                        x1="246.28"
                        y1="840.33"
                        x2="282.21"
                        y2="840.33"
                     />
                     <polyline
                        className="cls-4 svg-elem-18"
                        points="423.16 867.49 381.06 867.49 381.06 840.33 381.06 813.17 423.16 813.17"
                     />
                     <line
                        className="cls-4 svg-elem-19"
                        x1="381.07"
                        y1="840.33"
                        x2="416.99"
                        y2="840.33"
                     />
                     <polyline
                        className="cls-4 svg-elem-20"
                        points="304.83 813.17 333.22 813.17 361.62 813.17"
                     />
                     <line
                        className="cls-4 svg-elem-21"
                        x1="333.22"
                        y1="813.17"
                        x2="333.22"
                        y2="867.49"
                     />
                  </g>
               </g>
            </svg>
         </motion.div>
      </AnimatePresence>
   );
}
export default Splash;
