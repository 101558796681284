import React from "react";
// import Hell from "../../assets/sponsors/Hell.jpeg";
// import Holistik from "../../assets/sponsors/Holistik.jpeg";
// import Azorte from "../../assets/sponsors/Azorte.jpeg";
// import Habhit from "../../assets/sponsors/Habhit.jpeg";
// import Siuk from "../../assets/sponsors/Siuk.jpeg";
// import OffKicks from "../../assets/sponsors/OffKicks.jpeg";
// import SportFolio from "../../assets/sponsors/SportFolio.jpeg";
import SponsorsBg from "../../assets/sponsors/SponsorsBg.png";

import Axure from "../../assets/sponsors/osci-24/axure.png";
import Boombird from "../../assets/sponsors/osci-24/boombird.jpg";
import GFG from "../../assets/sponsors/osci-24/geeksforgeeks.jpg";
import GrowthSchool from "../../assets/sponsors/osci-24/growthschool.png";
import Hell from "../../assets/sponsors/osci-24/Hell.jpeg";
import NoEscape from "../../assets/sponsors/osci-24/noescape.jpg";
import Veraku from "../../assets/sponsors/osci-24/veraku.jpg";
import InterviewBuddy from "../../assets/sponsors/osci-24/interviewbuddy.JPG"; 

function Sponsors() {
   const images = [
      {
         name: "Axure",
         url: Axure,
         className: "sm:h-24 sm:w-24 animate-anime0 ",
      },
      {
         name: "Boombird",
         url: Boombird,
         className: "rounded-full h-16 w-16 animate-anime1",
      },
      {
         name: "GeeksForGeeks",
         url: GFG,
         className: "rounded-full h-20 w-20 animate-anime2",
      },
      {
         name: "GrowthSchool",
         url: GrowthSchool,
         className: "rounded-full h-22 w-22 animate-anime3",
      },
      {
         name: "Hell",
         url: Hell,
         className: "rounded-full h-18 w-18 animate-anime4",
      },
      {
         name: "NoEscape",
         url: NoEscape,
         className: "rounded-full h-24 w-24 animate-anime5",
      },
      {
         name: "Veraku",
         url: Veraku,
         className: "rounded-full h-24 w-24 animate-anime6",
      },
      {
         name: "Interview Buddy",
         url: InterviewBuddy,
         className: "rounded-full h-24 w-24 animate-anime6",
      },
   ];

   return (
      <>
         <div className=" overflow-hidden">
            <div
               className="w-full px-4 py-2 text-white bg-primary bg-cover "
               style={{ backgroundImage: `url(${SponsorsBg})` }}
            >
               <h1 className="text-4xl normal-case text-center font-bold mt-4 mb-4 md:mb:0">
                  Our Sponsors
               </h1>

               <p className="mb-8 mt-2 text-sm text-center opacity-70 hidden md:block md:px-40 normal-case ">
                  Throughout its illustrious journey, IETE has been embraced and
                  uplifted by the unwavering support of numerous sponsors, each
                  a beacon of commitment to technological advancement.
               </p>
            </div>
            <div
               className="w-full bg-primary py-4 flex gap-3 overflow-x-hidden overflow-y-hidden scrollbar-none webkit-scrollbar-hide whitespace-nowrap bg-center"
               style={{ backgroundImage: `url(${SponsorsBg})` }}
            >
               <div className="animate-infinite-scroll flex duration-100 ">
                  {images.map(
                     (
                        img,
                        index // Added index for unique keys
                     ) => (
                        <div
                           key={index}
                           className="m-4 mx-10 min-w-24 inline-block "
                        >
                           <img
                              src={img.url}
                              alt=""
                              className={`${img.className} duration-300`}
                           />
                           {/* Added duration-300 class */}
                        </div>
                     )
                  )}
               </div>
               <div className="animate-infinite-scroll flex duration-100 ">
                  {images.map((img, index) => (
                     <div
                        key={index}
                        className="m-4 mx-10 min-w-24 inline-block  "
                     >
                        <img
                           src={img.url}
                           alt=""
                           className={`${img.className} duration-300`}
                        />
                        {/* Added duration-300 class */}
                     </div>
                  ))}
               </div>
               <div className="animate-infinite-scroll speed flex duration-100 ">
                  {images.map((img, index) => (
                     <div
                        key={index}
                        className="m-4 mx-10 min-w-24 inline-block "
                     >
                        <img
                           src={img.url}
                           alt=""
                           className={`${img.className} duration-300`}
                        />
                        {/* Added duration-300 class */}
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </>
   );
}

export default Sponsors;
