import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ELAN from './Elan';
import GoldenSnitch from '../../components/GoldenSnitch/GoldenSnitch';
import audioFile from './audio.mp3';

function App() {
  const [showELAN, setShowELAN] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    // Display the initial toast after 5 seconds
    const toastTimer = setTimeout(() => {
      toast.info('Click anywhere on the page!', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
      });
    });

    // Set a timer to show ELAN after 4 seconds
    const elanTimer = setTimeout(() => {
      setShowELAN(true);
    }, 4000);

    return () => {
      clearTimeout(toastTimer);
      clearTimeout(elanTimer);
    };
  }, []);

  const handlePlayAudio = () => {
    if (audioRef.current && !audioPlayed) {
      audioRef.current.play().catch((error) => {
        console.log('Audio playback failed:', error);
      });
      setAudioPlayed(true);
      toast.dismiss(); // Dismiss the toast once clicked
    }
  };

  return (
    <div className="App" onClick={handlePlayAudio}>
      <audio ref={audioRef} src={audioFile} preload="auto" />

      <nav className="w-full bg-primary p-2">
        <h1 className="text-white text-xl">Navbar</h1>
      </nav>

      <GoldenSnitch />
      {showELAN && <ELAN />}

      <ToastContainer 
      className="!top-[calc(2rem+1rem)] !right-2" // Adjust the top value based on the navbar height
      toastClassName="bg-blue-500 text-white p-3 rounded-lg shadow-md"
      bodyClassName="text-sm"
      />
    </div>
  );
}

export default App;
