import React from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const ParticlesBackground = () => {
   // This function is necessary to load the slim version of tsparticles
   const customInit = async (engine) => {
      // This loads the slim preset, which includes basic particles animations
      await loadSlim(engine);
   };

   const particlesOptions = {
      // Customize your particles options here
      particles: {
         number: {
            value: 50,
            density: {
               enable: true,
               value_area: 800,
            },
         },
         color: {
            value: "#ffffff",
         },
         shape: {
            type: "circle",
         },
         opacity: {
            value: 0.5,
            anim: {
               enable: false,
            },
         },
         size: {
            value: 3,
            random: true,
            anim: {
               enable: false,
            },
         },
         line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
         },
         move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
               enable: false,
               rotateX: 600,
               rotateY: 1200,
            },
         },
      },
      interactivity: {
         detect_on: "canvas",
         events: {
            onhover: {
               enable: true,
               mode: "repulse",
            },
            onclick: {
               enable: true,
               mode: "push",
            },
            resize: true,
         },
         modes: {
            grab: {
               distance: 400,
               line_linked: {
                  opacity: 1,
               },
            },
            bubble: {
               distance: 400,
               size: 40,
               duration: 2,
               opacity: 8,
               speed: 3,
            },
            repulse: {
               distance: 200,
               duration: 0.4,
            },
            push: {
               particles_nb: 4,
            },
            remove: {
               particles_nb: 2,
            },
         },
      },
      retina_detect: true,
   };

   return (
      <Particles
         className="relative z-20"
         id="tsparticles"
         init={customInit}
         options={particlesOptions}
      />
   );
};

export default ParticlesBackground;
