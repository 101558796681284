import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
   Route,
   createBrowserRouter,
   createRoutesFromElements,
   RouterProvider,
} from "react-router-dom";

import Layout from "./Layout";
import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register-new";
import About from "./pages/About/About";
import Events from "./pages/Events/Events";
import Elan from "./pages/Elan/Elan";
import Tube from "./pages/Elan/Tube";
import ElanRender from "./pages/Elan/ElanRender";
import Howzat from "./pages/Register/Howzat";
// import Workshop from "./pages/Elan/Workshop";
// import Valorant from "./pages/Elan/Valorant";
// import TricksOfTrade from "./pages/Elan/TricksOfTrade";
// import TreasureHunt from './pages/Elan/TreasureHunt';
// import Scoreboard from "./pages/Scoreboard/Scoreboard.jsx";
// import DetailedMatchScore from "./components/ScoreSlider/DetailedMatchScore.jsx";
// import ScoreSlider from "./components/ScoreSlider/ScoreSlider.jsx";

const router = createBrowserRouter(
   createRoutesFromElements(
      <Route path="/" element={<Layout />}>
         <Route index element={<Home />} />
         <Route path="register" element={<Register />} />
         <Route path="about" element={<About />} />
         <Route path="events" element={<Events />} />
         <Route path="elan" element={<ElanRender />} />
         <Route path="elan/tube" element={<Tube />} />
         {/* <Route path="scoreboard" element={<Scoreboard />} /> */}
         {/* <Route path="elan/valorant" element={<Valorant />} /> */}
         {/* <Route path="elan/workshop" element={<Workshop />} /> */}
         {/* <Route path="elan/tricksoftrade" element={<TricksOfTrade />} /> */}
         {/* <Route path="elan/treasurehunt" element={<TreasureHunt/>}/> */}
         {/* <Route path="score-slider" element={<ScoreSlider />} />
         <Route path="detail/:matchId" element={<DetailedMatchScore />} /> */}
      </Route>
   )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <RouterProvider router={router} />
   </React.StrictMode>
);
