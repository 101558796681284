import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
import IeteLogo from "../../assets/iete-logo.png"; // Adjust the path accordingly

gsap.registerPlugin(TextPlugin);

const MainPage = () => {
   const ieteRef = useRef(null);
   const instituteRef = useRef(null);
   const logoRef = useRef(null);
   const content = useRef(null);

   useEffect(() => {
      const tl = gsap.timeline();

      tl.fromTo(
         [logoRef.current, ieteRef.current, instituteRef.current],
         { opacity: 0, y: 50 },
         { duration: 1.5, opacity: 1, y: 0, stagger: 0.5, ease: "power3.out" },
         0
      );
   }, []);

   return (
      <div className="relative h-screen w-screen overflow-hidden pt-16 flex justify-center items-center">
         <div className="absolute flex flex-col items-center justify-center text-white z-10">
            <div>
               <div className="flex items-center justify-center mb-4">
               <img
                  ref={logoRef}
                  src={IeteLogo}
                  alt="Logo"
                  className="w-[6rem] md:w-20 pb-2.5"
               />
               <div ref={ieteRef} className="text-6xl md:text-8xl">
                  IETE
               </div>
            </div>
            </div>
            <div ref={instituteRef} className="text-2xl text-center mx-4 md:text-3xl">
               Institution of Electronics and Telecommunication Engineering
            </div>
            <div
               ref={content}
               className="w-3/4 text-center m-4 mt-16 text-sm md:text-lg font-light"
            >
               Established in Thadomal Shahani Engineering College in 2012,
               IETE-TSEC is a technical committee that organizes many events,
               workshops, tech fests, and much more with the aim of facilitating
               the exchange of information and ideas among students. From
               engaging, interactive tech workshops to exciting, innovative
               non-technical events, we have it all!
            </div>
         </div>
      </div>
   );
};

export default MainPage;
