import React, { useState } from "react";
import { motion } from "framer-motion";

import Elan1 from "../../assets/gallery/elan/elan1.jpg";
import Elan2 from "../../assets/gallery/elan/elan2.jpg";
import Elan3 from "../../assets/gallery/elan/elan3.jpg";
import Elan4 from "../../assets/gallery/elan/elan4.jpg";
import Elan5 from "../../assets/gallery/elan/elan5.jpg";
import Elan6 from "../../assets/gallery/elan/elan6.jpg";
import Elan7 from "../../assets/gallery/elan/elan7.jpg";
import Elan8 from "../../assets/gallery/elan/elan8.jpg";
//Elan23
import Elan9 from "../../assets/gallery/elan/elan9 (1).jpg";
import Elan10 from "../../assets/gallery/elan/elan10.jpg";
import Elan11 from "../../assets/gallery/elan/elan11.jpg";
import Elan12 from "../../assets/gallery/elan/elan12.jpg";
import Elan13 from "../../assets/gallery/elan/elan13 (1).jpg";
import Elan14 from "../../assets/gallery/elan/elan14.jpg";
import Elan15 from "../../assets/gallery/elan/elan15.jpg";
import Elan16 from "../../assets/gallery/elan/elan16.jpg";
import Elan17 from "../../assets/gallery/elan/elan17.jpg";
import Elan18 from "../../assets/gallery/elan/elan18.jpg";

import Osci1 from "../../assets/gallery/oscillation/osci1.jpg";
import Osci2 from "../../assets/gallery/oscillation/osci2.jpg";
import Osci3 from "../../assets/gallery/oscillation/osci3.jpg";
import Osci4 from "../../assets/gallery/oscillation/osci4.jpg";
import Osci5 from "../../assets/gallery/oscillation/osci5.jpg";
import Osci6 from "../../assets/gallery/oscillation/osci6.jpg";
import Osci7 from "../../assets/gallery/oscillation/osci7.jpg";
import Osci8 from "../../assets/gallery/oscillation/osci8.jpg";
import Osci9 from "../../assets/gallery/oscillation/osci9.jpg";
import Osci10 from "../../assets/gallery/oscillation/osci10.jpg";
import Osci11 from "../../assets/gallery/oscillation/osci11.jpg";
import Osci12 from "../../assets/gallery/oscillation/osci12.jpg";
import Osci13 from "../../assets/gallery/oscillation/osci13.jpg";
import Osci14 from "../../assets/gallery/oscillation/osci14.jpeg";
import Osci15 from "../../assets/gallery/oscillation/osci15.jpeg";
import Osci16 from "../../assets/gallery/oscillation/osci16.png";

import md1 from "../../assets/gallery/membershipDrive/md1.jpg";
import md2 from "../../assets/gallery/membershipDrive/md2.jpg";
import md3 from "../../assets/gallery/membershipDrive/md3.jpg";
import md4 from "../../assets/gallery/membershipDrive/md4.jpg";
import md5 from "../../assets/gallery/membershipDrive/md5.jpg";
import md6 from "../../assets/gallery/membershipDrive/md6.jpg";

import Random1 from "../../assets/gallery/Random/Random1.jpg";
import Random2 from "../../assets/gallery/Random/Random2.jpg";
import Random3 from "../../assets/gallery/Random/Random3.jpg";
import Random4 from "../../assets/gallery/Random/Random4.jpg";
import Random5 from "../../assets/gallery/Random/Random5.jpg";
import Random6 from "../../assets/gallery/Random/Random6.jpg";
import Random7 from "../../assets/gallery/Random/Random7.jpg";
import Random8 from "../../assets/gallery/Random/Random8.jpg";
import Random9 from "../../assets/gallery/Random/Random9.jpg";
import Random10 from "../../assets/gallery/Random/Random10.jpeg";
import Random11 from "../../assets/gallery/Random/Random11.jpg";
import Random12 from "../../assets/gallery/Random/Random12.jpg";
import Random13 from "../../assets/gallery/Random/Random13.jpg";
import Random14 from "../../assets/gallery/Random/Random14.jpg";
import Random15 from "../../assets/gallery/Random/Random15.jpg";

const events = [
   {
      id: 1,
      title: "ELAN 2023",
      description:
         "Experience the fusion of excitement and education at ELAN 2023. Dive into the Stock Market Game, conquer Inquizition's intellectual challenges, and gain insights from expert-led webinars. Beyond the competitions, ELAN is a vibrant celebration of camaraderie and joy",

      image: Elan15,
      additionalImages: [
         Elan18,
         Elan9,
         Elan10,
         Elan11,
         Elan12,
         Elan13,
         Elan14,
         Elan16,
         Elan17,
      ],
   },
   {
      id: 2,
      title: "OSCILLATION 2023",
      description:
         "In 2023, we orchestrated an event featuring a thrilling cricket auction for crafting dream teams, a beginner-friendly web design hackathon called Newbiethon, an intriguing murder mystery titled How I Met Your Murderer, and an enriching resume-building workshop.",
      image: Osci8,
      additionalImages: [
         Osci1,
         Osci2,
         Osci3,
         Osci4,
         Osci5,
         Osci6,
         Osci7,
         Osci8,
      ],
   },
   {
      id: 3,
      title: "ELAN 2022",
      description:
         "The odd event semester was better than ever! Elan 2022 had tech, business pitches, gaming and everything in between! 3 days of power packed events like Pitch perfect, Blockchain Workshop, Inquisition, Osiris - A Valorant Tournament, Codequest, and last but not the least, Let's Crack It!",
      image: Elan6,
      additionalImages: [
         Elan1,
         Elan2,
         Elan3,
         Elan4,
         Elan5,
         Elan6,
         Elan7,
         Elan8,
      ],
   },
   {
      id: 4,
      title: "OSCILLATION 2022",
      description:
         "After 2 years of online events, Oscillation 2022 was back with a bang! We had fascinating non-tech events like Escape Room, a BGMI Tournament, and an IPL Auction as well as intriguing technical workshops in particular Roadmap to Hackathon, Cryptocurrency and NFTs, Data Structures and Algorithms, UI/UX and Open Source Intelligence.",
      image: Random5,
      additionalImages: [
         Osci9,
         Osci10,
         Osci11,
         Osci12,
         Osci13,
         Osci14,
         Osci15,
         Osci16,
      ],
   },
   {
      id: 5,
      title: "ELAN 2021",
      description:
         "Elan 2021 was an extravagant odd semester tech fest of IETE TSEC! A perfect blend of technical and non-technical events. They were 3 days of fun, learning and memories galore! The events lined up were Trivia Night, Workshop on Machine Learning, Workshop on Resume Building, D’Cipher- A cryptic hunt, and last but not the least, a Workshop on Artificial Intelligence.",
      image: Random7,
      additionalImages: [Random1, Random2, Random3, Osci15, Random5],
   },
   {
      id: 6,
      title: "OSCILLATION 2021",
      description:
         "We had thrilling non-tech events like Call of Duty and Valorant Gaming Tournaments, Escape Room, and a Virtual IPL Auction along with inspiring technical workshops based on Python, Innovations in Laptop Design, UI/UX, Headstart to Competitive Programming, and Spark AR Workshop.",
      image: Random8,
      additionalImages: [Random6, Random7, Random8, Random9, Random10],
   },
   {
      id: 7,
      title: "MEMBERSHIP DRIVE 2019",
      description:
         "The Membership Drive of 2019 was organised for FEs. It was a 5-day long event (each day had a theme) to get students to know more about IETE. There were fun events like a VR Zombie game and an AR Game where participants had to pick a card and it would reveal a particular movie/book to them, depending on the day's theme. There were placard photo booths as well. Winners received prizes and coupons and all those who participated were given a goodie bag with IETE merchandise.",
      image: Elan6,
      additionalImages: [md1, md2, md3, md4, md5, md6],
   },
   {
      id: 8,
      title: "OSCILLATION 2019",
      description:
         "Oscillation 2019 was full of advanced technical workshops on compelling topics like Python, Machine Learning, Android Workshop, Ethical Hacking, Virtual Reality, and Web Development.",
      image: Random4,
      additionalImages: [Random11, Random12, Random13, Random14, Random15],
   },
];

function Events() {
   const [selectedEvent, setSelectedEvent] = useState(null);
   const [activeImageIndex, setActiveImageIndex] = useState(0);

   // //animation
   // const distortStyle = {
   //    animation: "distort 2s infinite",
   // };

   const openPopup = (event) => {
      setSelectedEvent(event);
      setActiveImageIndex(0);
   };

   const closePopup = () => {
      setSelectedEvent(null);
   };

   const nextImage = () => {
      setActiveImageIndex((prevIndex) =>
         prevIndex === selectedEvent.additionalImages.length - 1
            ? 0
            : prevIndex + 1
      );
   };

   const prevImage = () => {
      setActiveImageIndex((prevIndex) =>
         prevIndex === 0
            ? selectedEvent.additionalImages.length - 1
            : prevIndex - 1
      );
   };

   const imageStyle = (index, arrayLength) => {
      let offset = index - activeImageIndex;
      if (offset < 0) {
         offset += arrayLength;
      }
      if (offset > arrayLength / 2) {
         offset -= arrayLength;
      }
      return { transform: `translateX(${offset * 100}%)` };
   };

   const variants = {
      visible: (i) => ({
         opacity: 1,
         x: 0,
         transition: {
            delay: i * 0.5,
            duration: 0.4,
         },
      }),
      hidden: (i) => ({ opacity: 0, x: i % 2 === 0 ? -50 : 50 }),
   };

   return (
      <div className="bg-primary min-h-screen pt-24 relative">
         {/* <div className="flex items-center justify-center  text-4xl font-extrabold text-white text-center mb-12 bg-transparent m-auto py-10 md:py-20 md:w-1/6 relative">
       <h2 className="text-glow">PAST EVENTS</h2>
     </div> */}

         <div className="w-full h-[60vh] flex items-center justify-center bg-primary">
            <div className="absolute top-[50px] justify-center inline-flex flex-row inset-0">
               <div className="bg-light opacity-60 w-[400px] h-[100px] rounded-full  overflow-hidden relative filter blur-[80px] animate-gradientMesh_1"></div>
               <div className="bg-light2 opacity-60 w-[300px] h-[100px] rounded-full overflow-hidden relative filter blur-[80px] animate-gradientMesh_2"></div>
               <div className="bg-light opacity-60 w-[400px] h-[100px] rounded-full overflow-hidden relative filter blur-[80px] animate-gradientMesh_1"></div>
            </div>
            <motion.div
               className=" text-white w-full"
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}
               transition={{ delay: 0, duration: 2 }}
            >
               <motion.h1 className="text-6xl mt-16 text-primary font-extrabold mb-2  text-center transition-all ease-in">
                  Past Events
               </motion.h1>
            </motion.div>
         </div>

         <div className="absolute left-1/2 transform -translate-x-1/2 w-0.5 bg-white h-3/4"></div>

         <div className="flex flex-col items-center relative">
            {events.map((event, index) => (
               <motion.div
                  key={event.id}
                  custom={index}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants}
                  className={`mb-12 w-1/2 max-w-xl p-6 bg-gradient-radial text-white rounded-lg flex ${
                     index % 2 === 0 ? "ml-[10%] mr-auto" : "mr-[10%] ml-auto"
                  } relative`}
                  onClick={() => openPopup(event)}
               >
                  {/* Adjust the dot positioning and styling
            <div
              className={`absolute ${
                index % 2 === 0 ? "right-0" : "left-0"
              } top-1/2 transform -translate-y-1/2 w-4 h-4 bg-gradient-to-br from-blue-500 to-green-500 rounded-full z-10`}
            ></div> */}

                  <div className="flex flex-col md:flex-row md:items-center gap-4">
                     <div className="md:flex-shrink-0">
                        <img
                           src={event.image}
                           alt={event.title}
                           className="h-auto rounded-lg md:w-48"
                        />
                     </div>
                     <div className="text-left">
                        <h2 className="text-2xl font-semibold">
                           {event.title}
                        </h2>
                        <p className="mt-2 text-lg">{event.description}</p>
                     </div>
                  </div>
               </motion.div>
            ))}
         </div>

         {selectedEvent && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
               <div className="p-4 rounded-lg shadow-lg relative w-full h-full max-w-5xl max-h-[36rem] overflow-hidden">
                  <button
                     className="absolute top-0 right-0 m-4 text-3xl font-bold text-white z-10"
                     onClick={closePopup}
                  >
                     ❌
                  </button>
                  <div className="flex items-center justify-center h-full relative">
                     {selectedEvent.additionalImages.map((image, index) => (
                        <img
                           key={index}
                           src={image}
                           alt={`Slide ${index}`}
                           className={` ${
                              index === activeImageIndex
                                 ? `opacity-100 scale-100 z-10`
                                 : `opacity-0 scale-0`
                           } transition-all duration-500 ease-in-out w-auto h-3/4 absolute`}
                           style={imageStyle(
                              index,
                              selectedEvent.additionalImages.length
                           )}
                        />
                     ))}
                  </div>
                  {selectedEvent.additionalImages.length > 1 && (
                     <>
                        <button
                           onClick={prevImage}
                           className="absolute left-2 top-1/2 transform -translate-y-1/2 text-4xl font-bold text-white"
                        >
                           ⟨
                        </button>
                        <button
                           onClick={nextImage}
                           className="absolute right-2 top-1/2 transform -translate-y-1/2 text-4xl font-bold text-white"
                        >
                           ⟩
                        </button>
                     </>
                  )}
               </div>
            </div>
         )}
      </div>
   );
}

export default Events;
