import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Elan from "../../assets/gallery/elan/elan16.jpg";
import Osci from "../../assets/gallery/oscillation/osci14.jpg";
import { Link } from "react-router-dom";

function HomeEvents() {
   const info = [
      "IETE-TSEC is back with its one-of-a-kind series.. In a Nutshell Join us for another season jam-packed with college wisdom, adulting insights, and insightful tech discussions.",
      "The odd event semester was better than ever! Elan 2022 had tech, business pitches, gaming and everything in between! 3 days of power packed events like B.G.M.I, Stock Market Event, CyberSecurity Workshop and last but not the least, Inquizition 2.0!",
      "In 2023, we orchestrated an event featuring a thrilling cricket auction for crafting dream teams, a beginner-friendly web design hackathon called Newbiethon, an intriguing murder mystery titled How I Met Your Murderer, and an enriching resume-building workshop.",
   ];

   const contentArray = [
      "https://www.ietetsec.in/static/media/introvideo.139737c379c318f4eb05.mp4",
      Elan,
      Osci,
   ];

   const [text, setText] = useState(info[0]);
   const [activeButton, setActiveButton] = useState(0);
   const [content, setContent] = useState(
      <motion.video
         className="w-full h-full object-cover"
         autoPlay
         muted
         loop
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.5 }}
      >
         <source src={require("./Podcast.mp4")} type="video/mp4"></source>
      </motion.video>
   );

   const handleClick = (id, type) => {
      setText(info[id]);
      setActiveButton(id);
      if (type === "image") {
         setContent(
            <motion.img
               src={contentArray[id]}
               alt="Image"
               className="bg-center h-full w-full rounded-3xl hover:scale-110 transition-all ease duration-500"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.5 }}
            />
         );
      } else {
         setContent(
            <a
               href="https://www.youtube.com/watch?v=4Oj7mEF6TtA"
               target="_blank"
               rel="noopener noreferrer"
            >
               <motion.video
                  key={id}
                  className="w-full h-full object-fill"
                  autoPlay
                  muted
                  loop
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
               >
                  <source
                     src={require("./Podcast.mp4")}
                     type="video/mp4"
                  ></source>
               </motion.video>
            </a>
         );
      }
   };

   return (
      <div className="w-full h-[120vh] bg-light flex justify-center items-center">
         <div className="bg-primary w-[95%] h-[90%] mx-auto my-auto lg:flex rounded-3xl">
            <div className=" h-[60%] flex flex-col text-white rounded-3xl lg:w-[40%] lg:h-full lg:justify-center lg:items-center">
               <div className="mt-16 ml-6 lg:mt-0 lg:ml-0">
                  <button
                     className={`block text-5xl pl-4 border-l-2 lg:pb-3 lg:text-6xl ${
                        activeButton === 0
                           ? "border-light opacity-100"
                           : "border-gray-500 opacity-30"
                     }  transition-opacity duration-500 ease-in-out`}
                     onClick={() => {
                        handleClick(0, "video");
                     }}
                  >
                     Podcast
                  </button>
                  <button
                     className={`block text-5xl pl-4 border-l-2 lg:pb-3 lg:text-6xl ${
                        activeButton === 1
                           ? "border-light opacity-100"
                           : "border-gray-500 opacity-30"
                     }  transition-opacity duration-500 ease-in-out`}
                     onClick={() => {
                        handleClick(1, "image");
                     }}
                  >
                     Elan
                  </button>
                  <button
                     className={`block text-5xl pl-4 border-l-2 lg:pb-3 lg:text-6xl  ${
                        activeButton === 2
                           ? "border-light opacity-100"
                           : "border-gray-500 opacity-30"
                     }  transition-opacity duration-500 ease-in-out`}
                     onClick={() => {
                        handleClick(2, "image");
                     }}
                  >
                     Oscillation
                  </button>
               </div>
               <div className="my-0">
                  <motion.p
                     key={`text-${text}`}
                     className="mt-8 normal-case  text-center text-gray-100  opacity-90 ml-6 lg:ml-0 mr-8 lg:mr-0 lg:px-20 text-sm "
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ duration: 0.5 }}
                  >
                     {text}
                  </motion.p>
                  <div className="flex items-center justify-center mx-auto mt-4">
                     {activeButton === 0 && (
                        <button className=" z-20 w-40 mx-auto rounded-lg px-2 py-2 my-2 md:py-3 text-sm  border bg-primary border-light hover:bg-light cursor-pointer">
                           <Link
                              to="https://www.youtube.com/watch?v=4Oj7mEF6TtA"
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              Watch Now
                           </Link>
                        </button>
                     )}
                  </div>
               </div>
            </div>
            <div className=" h-[40%] overflow-hidden lg:w-[60%] lg:h-full">
               <AnimatePresence mode="wait">{content}</AnimatePresence>
            </div>
         </div>
      </div>
   );
}

export default HomeEvents;
